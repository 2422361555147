<template>
  <div v-on:keyup.enter="submitForm()">
    <ods-main-header headerLogoText="Onesait Balances" logoUrl="/">
    </ods-main-header>

    <div class="app-wrap">
      <ods-main>
        <ods-scrollbar wrapClass="ods-scrollbar--main-content">
          <ods-row type="flex" justify="center">
            <ods-col :md="7">
              <ods-module>
                <ods-form
                  class="login-form"
                  label-position="top"
                  :model="loginForm"
                  :rules="validations"
                  ref="loginForm"
                  v-loading="loading"
                >
                  <ods-row>
                    <ods-col>
                      <ods-form-item :label="$t('email')" prop="email">
                        <ods-input
                          v-model="loginForm.email"
                          type="email"
                        ></ods-input>
                      </ods-form-item>
                    </ods-col>
                  </ods-row>
                  <ods-row>
                    <ods-col>
                      <ods-form-item :label="$t('password')" prop="password">
                        <ods-input
                          v-model="loginForm.password"
                          type="password"
                          show-password
                        ></ods-input>
                      </ods-form-item>
                    </ods-col>
                  </ods-row>
                  <ods-row>
                    <ods-col>
                      <ods-form-item :label="$t('section')" prop="module">
                        <ods-radio-group v-model="loginForm.module">
                          <ods-radio :label="1">
                            <img src="../assets/lightbulb-regular.svg" alt="" />
                          </ods-radio>
                          <ods-radio :label="2">
                            <img src="../assets/droplet-solid.svg" alt="" />
                          </ods-radio>
                        </ods-radio-group>
                      </ods-form-item>
                    </ods-col>
                  </ods-row>

                  <ods-row type="flex" justify="center">
                    <ods-button type="primary" @click="submitForm()">
                      {{ $t("login") }}
                    </ods-button>
                  </ods-row>
                </ods-form>
              </ods-module>
            </ods-col>
          </ods-row>
        </ods-scrollbar>
      </ods-main>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/Auth";
import handlePromise from "@/utils/promise";
import errors from "@/config/InputErrors";
import UserService from "@/services/User";

export default {
  name: "Login",
  mounted() {
    this.$store.commit("user/logout");
    this.$store.commit("userModule/logout");
    this.$store.commit("userRoles/logout");
  },
  data() {
    return {
      loading: false,
      loginForm: {
        module: 1,
      },
      validations: {
        email: [errors.required, errors.email],
        password: [errors.required],
        module: [errors.required],
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem("reload", true);
    next();
  },
  methods: {
    submitForm() {
      this.$refs["loginForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const [error, response, data] = await handlePromise(
            AuthService.login({
              username: this.loginForm.email, // 'admin@sauna.com',
              password: this.loginForm.password, // '123456'
              module: this.loginForm.module === 1 ? "ENERGY" : "WATER",
            })
          );
          this.loading = false;
          if (!response.ok)
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          this.$store.commit("user/updateUser", data);
          delete this.loginForm["password"];
          this.$store.commit("userModule/updateUserModule", this.loginForm);
          this.loading = true;
          const [, responseDataUser, dataUser] = await handlePromise(
            UserService.getUser(data.user.id)
          );
          if (!responseDataUser.ok)
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          this.$store.commit("userRoles/updateUserRoles", dataUser.roles);
          this.loading = false;

          if (this.loginForm.module === 1) {
            this.$router.push({ path: "/balance-energy" });
          } else {
            this.$router.push({ path: "/balance-water" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  margin-top: 50px;
}
</style>
